import React from 'react';
import { Container, Row, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css'; 
import { FaGlobe, FaWhatsapp } from 'react-icons/fa'; // Import the globe icon from react-icons

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const PrivacyPolicyAndTermsOfUse = () => {
  const { t, i18n } = useTranslation(); // Get i18n instance to change language

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language); // Change the language using i18n
  };

  const whatsappLink = `https://wa.me/+213560345302?text=${encodeURIComponent('Privacy / Terms of use')}`;

  return (
    <Container className="privacy-policy mt-5 p-4" style={{ maxWidth: '900px', background: '#f5f5f5', borderRadius: '8px' }}>
      <Row className="mb-3 d-flex justify-content-end align-items-center">
        <DropdownButton id="language-dropdown" title={
          <span>
            <FaGlobe className="m-1" />
            <span className="m-1">{languageLabels[i18n.language]}</span>
          </span>
        }>
          <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
          <Dropdown.Item onClick={() => handleLanguageChange('fr')}>Français</Dropdown.Item>
          <Dropdown.Item onClick={() => handleLanguageChange('ar')}>العربية</Dropdown.Item>
        </DropdownButton>
      </Row>

      <Row className="mb-5 text-center">
        <div className="custom-sidebar" style={{ color: '#4A00E0' }}>
          <h1 className="logo-font">COD Pricing</h1>
          <h5>{t('Pricing platform for e-commerce')}</h5>
        </div>
      </Row>
      
      <h1 className="mt-5 mb-3">{t('termsOfUse')}</h1>

      <Section title={t('terms.acceptanceOfTerms')} content={t('terms.acceptanceDescription')} />
      <Section title={t('terms.serviceDescription')} content={t('terms.serviceDescriptionDetail')} />
      <Section title={t('terms.userObligations')} content={t('terms.userObligationsDetail')} />
      <Section title={t('terms.intellectualProperty')} content={t('terms.intellectualPropertyDetail')} />
      <Section title={t('terms.limitationOfLiability')} content={t('terms.limitationOfLiabilityDetail')} />
      <Section title={t('terms.governingLaw')} content={t('terms.governingLawDetail')} />
      <Section title={t('terms.changes')} content={t('terms.changesDetail')} />

      <h1 className="mt-5 mb-3">{t('privacyPolicy')}</h1>
      <Section title={t('privacy.dataCollection')} content={t('privacy.dataCollectionDetail')} />
      <Section title={t('privacy.useOfData')} content={t('privacy.useOfDataDetail')} />
      <Section title={t('privacy.dataSharing')} content={t('privacy.dataSharingDetail')} />
      <Section title={t('privacy.cookies')} content={t('privacy.cookiesDetail')} />
      <Section title={t('privacy.dataSecurity')} content={t('privacy.dataSecurityDetail')} />
      <Section title={t('privacy.userRights')} content={t('privacy.userRightsDetail')} />
      <Section title={t('privacy.changes')} content={t('privacy.changesDetail')} />
 {/* Disclaimer Section */}
 <Section title={t('Disclaimer')} content="This is a general disclaimer about the usage of our platform. Please ensure you understand the terms fully before proceeding." />

{/* Contact Us Section */}
<div className="mt-5 text-center">
  <h5>Contact Us</h5>
  <div>You can contact us using this email address : support@codpricing.com</div>
  <Button variant="success" className="m-2" href={whatsappLink} target="_blank">
    <FaWhatsapp className="m-1" /> Send us a message on WhatsApp
  </Button>
</div>

</Container>  );
};

const Section = ({ title, content }) => (
  <div className="mt-5">
    <h5 className="section-title mb-3 font-weight-bold">{title}</h5>
    <p>{content}</p>
  </div>
);

export default PrivacyPolicyAndTermsOfUse;