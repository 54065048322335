import React, { useState } from 'react';
import axios from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert, InputGroup, Dropdown } from 'react-bootstrap';
import { FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Avatar1 from '../assets/avatar1.jpg';
import Avatar2 from '../assets/avatar2.jpg';

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const Signup = () => {
  const [form, setForm] = useState({ name: '', email: '', confirmEmail: '', country: '', phoneNumber: '', avatar: 'avatar1', password: '' });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleAvatarChange = (avatar) => {
    setForm({ ...form, avatar });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.name) newErrors.name = t('Name is required.');
    if (!form.email) newErrors.email = t('Email is required.');
    if (!form.country) newErrors.country = t('Country is required.');
    if (!form.phoneNumber) newErrors.phoneNumber = t('Phone number is required.');
    if (!form.password) newErrors.password = t('Password is required.');
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      console.log(newErrors,'heere');
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post('/signup', form);
      const messageKey = response.data.messageKey;

      setErrorMessage('');
      setSuccessMessage(t(messageKey));

      navigate('/verify-email');
    } catch (error) {
      const messageKey = error.response?.data?.messageKey || 'signupError';

      if (error.response?.data?.resendLink) {
        setErrorMessage(
          <>
            {t(messageKey)} <br />
            <Button variant="link" id='ResendVerificationEmailSignup' onClick={() => resendVerificationEmail()}>{t('Resend Verification Email')}</Button>
          </>
        );
      } else {
        setErrorMessage(t(messageKey));
      }

      setSuccessMessage('');
    }
  };

  const resendVerificationEmail = async () => {
    try {
      await axios.post('/signup/resend-verification', { email: form.email });
      setSuccessMessage(t('Verification email resent. Please check your inbox.'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(t('Failed to resend verification email.'));
      setSuccessMessage('');
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <div className="custom-container">
      <div className="custom-sidebar">
        <h1 className="logo-font">COD Pricing</h1>
        <h5>{t('Pricing platform for e-commerce')}</h5>
      </div>
      <Container className="d-flex col-lg-8 col-md-10 align-items-center justify-content-center">
        <Row className="w-100 justify-content-center">
          <Col md={10} lg={8} className="d-flex justify-content-center">
            <div className="custom-form-container">
              <h1 className="text-center mb-4">{t('Create your account')}</h1>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="m-3">
                  <Form.Label>{t('Choose Avatar')}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <div className="avatar-container" onClick={() => handleAvatarChange('avatar1')}>
                      <img
                        src={Avatar1}
                        alt="Avatar 1"
                        className={`avatar-img ${form.avatar === 'avatar1' ? 'selected' : ''}`}
                      />
                    </div>
                    <div className="avatar-container" onClick={() => handleAvatarChange('avatar2')}>
                      <img
                        src={Avatar2}
                        alt="Avatar 2"
                        className={`avatar-img ${form.avatar === 'avatar2' ? 'selected' : ''}`}
                      />
                    </div>
                  </div>
                </Form.Group>
                <Row className="m-3">

                <Form.Group>
                  <Form.Label>{t('Name')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      autoComplete="name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
</Row>
                <Row className="m-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>{t('Email')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="email"
                          name="email"
                          value={form.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          autoComplete="email"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="m-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>{t('Country')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          name="country"
                          value={form.country}
                          onChange={handleChange}
                          isInvalid={!!errors.country}
                          className='custom-select'
                        >
                          <option value="">{t('Choose...')}</option>
                          <option value="Algeria">{t('Algeria')}</option>
                          <option value="Morocco">{t('Morocco')}</option>
                          <option value="Tunisia">{t('Tunisia')}</option>
                          <option value="Libya">{t('Libya')}</option>
                          <option value="Other">{t('Other')}</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.country}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>{t('Phone Number')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          value={form.phoneNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="m-3">

                <Form.Group>
                  <Form.Label>{t('Password')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="password"
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      className="password-input"
                      autoComplete="current-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
</Row>
                <div className="d-flex justify-content-center m-2">
                  <Button variant="primary" type="submit" className="mt-4 w-50 mb-2 custom-button">
                    {t('Create your account')}
                  </Button>
                </div>

                <div className="text-center mt-2">
    {t('By continuing, you agree to our')} <Button variant="link" className="p-0 m-0" onClick={() => window.open('/privacy-policy-and-terms-of-use', '_blank')}>{t('Privacy Policy and Terms of Use')}</Button> 
</div>

              </Form>

             

              <div className="text-center mt-3">
                <Button variant="link" className="auth-link" onClick={() => navigate('/')}>
                {t('Already have an account?')}
                </Button>
              </div>

              <div className="d-flex justify-content-end">
                <Dropdown className="me-3 custom-dropdown">
                  <Dropdown.Toggle variant="light" id="language-dropdown" className="custom-dropdown-toggle">
                    <FaGlobe className="m-1" />
                    <span className="m-1">{languageLabels[i18n.language]}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(languageLabels).map((lang) => (
                      <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                        {languageLabels[lang]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Signup;