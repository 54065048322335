import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Login from './pages/Login/Login';
import Signup from './components/Signup';
import Fees from './pages/FeeVariation/FeesVariation';
import EmailVerification from './components/EmailVerfication';
import EmailVerificationConfirm from './components/EmailVerficiationConfirm';
import Simulations from './components/Simulation';
import SimulationDetails from './components/SimulationDetails';
import DashboardLayout from './components/DashboardLayout';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import ProtectedRoute from './components/ProtectedRoute';
import FeeVariationDetails from './components/FeesVariationDetails';
import './styles.css'
import './style.css'
import './variable.css'
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

import Dashboard from './pages/Dashboard/Dashboard';
import CallCenter from './pages/CallCenter/CallCenter';
import Plans from './pages/Plans/Plans';
import Profile from './pages/Profile/Profile';
import Comparison from './pages/Comparaison/Comparaison';
import CryptomusWebhookTester from './pages/AdminWebhookTest/CryptomusWebhookTester';

import { UserProvider } from './contexts/UserContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import PayManually from './components/PayManually';
import PrivacyPolicyAndTermsOfUse from './components/PrivacyPolicyAndTermsOfUse';
import AdminPanel from './components/Admin/AdminPanelLayout';
import AdminRoute from './components/Admin/AdminRoute';
import Unauthorized from './components/Unauthorized';
import Users from './pages/AdminUsers/Users';
import Payments from './pages/AdminPayments/Payments';

ReactGA.initialize('G-RGPCDPVDJC');


function App() {
  const location = useLocation();

  useEffect(() => {
    // Log each page view
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <UserProvider>
      <SubscriptionProvider>

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/confirm-email/:token" element={<EmailVerificationConfirm />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/privacy-policy-and-terms-of-use" element={<PrivacyPolicyAndTermsOfUse />} />

          {/* Dashboard routes require authentication */}
          <Route element={<AuthenticatedRoute />}>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="home" element={<Dashboard />} />
              <Route path="plans" element={<Plans />} />
              <Route path='pay-manually' element={<PayManually />} />
              {/* Protected Routes require subscription */}
              <Route element={<ProtectedRoute />}>
                <Route path="fees" element={<Fees />} />
                <Route path="fees/:id" element={<FeeVariationDetails />} />
                <Route path="simulations" element={<Simulations />} />
                <Route path="simulations/:id" element={<SimulationDetails />} />
                <Route path="call-center" element={<CallCenter />} />
                <Route path="comparaison" element={<Comparison />} />
                <Route path="profile" element={<Profile />} />
              </Route>
            </Route>
          </Route>

          {/* Admin routes */}
          <Route element={<AdminRoute />}>
            <Route path="/admin" element={<AdminPanel />}>
              <Route path="users" element={<Users />} />
              <Route path='payments' element={<Payments />} />
              <Route path='testCrypto' element={<CryptomusWebhookTester />} />
            </Route>
            {/* Unauthorized access */}
            <Route path="unauthorized" element={<Unauthorized />} />
          </Route>
        </Routes>
      </SubscriptionProvider>
    </UserProvider>
  );
}

export default App;