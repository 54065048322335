import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Nav, Navbar, Image, Dropdown, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faBars, faGlobe, faProjectDiagram, faEnvelope, faCodeCompare, faPhone, faDollarSign, faBell, faSignOutAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/logo.png';
import './DashboardLayout.css';
import { LanguageContext } from '../contexts/LanguageContext';
import Avatar1 from '../assets/avatar1.jpg';
import Avatar2 from '../assets/avatar2.jpg';
import axios from '../utils/axios';
import { UserContext } from '../contexts/UserContext';
import { useSubscription } from '../contexts/SubscriptionContext';

import moment from 'moment';
import { FaInstagram, FaYoutube } from 'react-icons/fa';

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const [isSubscriptionDue, setIsSubscriptionDue] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(null);

  const navigate = useNavigate();
  const { user, updateUserContext } = useContext(UserContext);
  const { subscriptionEndDate, isCancelled, fetchSubscriptionStatus } = useSubscription();

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const whatsappLink = `https://wa.me/+213560345302?text=${encodeURIComponent('Login Issue')}`;

  useEffect(() => {
    document.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const handleLogout = async () => {
    try {
      await axios.get('/auth/logout');
      localStorage.removeItem('avatar');
      localStorage.removeItem('name');
      updateUserContext({ avatar: '', name: '' });
    } catch (error) {
      console.error('Error during logout:', error);
    }
    navigate('/');
  };

  const getAvatarSrc = () => {
    if (user.avatar === 'avatar1') return Avatar1;
    if (user.avatar === 'avatar2') return Avatar2;
    return Avatar1;
  };

  useEffect(() => {
    const checkSubscriptionDue = async () => {
      await fetchSubscriptionStatus();

      if (!isCancelled && subscriptionEndDate) {
        const endDate = moment(subscriptionEndDate);
        const now = moment();
        const oneWeekFromNow = moment().add(7, 'days');

        if (endDate.isBetween(now, oneWeekFromNow, null, '[)')) {
          setIsSubscriptionDue(true);
          setDaysRemaining(endDate.diff(now, 'days'));
        } else {
          setIsSubscriptionDue(false);
        }
      } else {
        setIsSubscriptionDue(false);
      }
    };
    checkSubscriptionDue();
  }, [subscriptionEndDate, isCancelled, fetchSubscriptionStatus]);

  return (
    <Container fluid className='dashboard-layout d-flex flex-column'>
      <Row>
        <Col xs={12} className="p-1 header">
          <Navbar expand="lg" className="shadow-sm d-flex justify-content-between align-items-center navbarCodPricing">
            <Navbar.Brand as={NavLink} to="/dashboard/simulations" className='logo-font d-flex align-items-center '>
              <h5>Cod Pricing</h5>
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <Nav.Item>
                <FontAwesomeIcon icon={faBell} style={{ fontSize: '2rem', color: '#F8F9FA', margin: '0 1rem' }} />
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon 
                  icon={faBars} 
                  onClick={handleSidebarToggle}
                  className='sidebar-toggle d-lg-none'
                />
              </Nav.Item>
              <div className='settings'>
                <Nav.Item>
                  <Nav.Link className="p-0">
                    <Dropdown className="me-3 custom-dropdown">
                      <Dropdown.Toggle variant="light" id="language-dropdown" className="custom-dropdown-toggle">
                        <FontAwesomeIcon icon={faGlobe} className="m-1" />
                        <span className="m-1">{languageLabels[language]}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {Object.keys(languageLabels).map((lang) => (
                          <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                            {languageLabels[lang]}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="p-0">
                    <Dropdown className="me-3 custom-dropdown">
                      <Dropdown.Toggle variant="light" id="avatar-dropdown" className="custom-dropdown-toggle d-flex align-items-center">
                        <Image src={getAvatarSrc()} roundedCircle width="30" height="30" className="me-2" />
                        <span>{user.name}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={NavLink} to="/dashboard/profile">Profile</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Navbar>
        </Col>
      </Row>
  
      {isSubscriptionDue && (
        <Alert variant="warning" className="text-center mt-7 alert-subscription" >
          {t('Your subscription will end in {{days}} days on {{date}}', { days: daysRemaining, date: moment(subscriptionEndDate).format('MMMM Do YYYY') })}.&nbsp;
          {t('Please renew your subscription to continue using our services.')}
          <Button variant="primary" onClick={() => navigate('/dashboard/plans')}>
            {t('Renew Now')}
          </Button>
        </Alert>
      )}
  
      <Row className="flex-grow-1">
        <Col xs={12} lg={2} className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <Nav className="flex-column mt-4">
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/home">
                <FontAwesomeIcon icon={faHome} className="me-2 ms-2" />{t('NAVIGATION.HOME')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/fees">
                <FontAwesomeIcon icon={faProjectDiagram} className="me-2 ms-2" />{t('NAVIGATION.FEES')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/simulations">
                <FontAwesomeIcon icon={faFolderOpen} className="me-2 ms-2" />{t('NAVIGATION.SIMULATIONS')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/comparaison">
                <FontAwesomeIcon icon={faCodeCompare} className="me-2 ms-2" />{t('NAVIGATION.COMPARAISON')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/call-center">
                <FontAwesomeIcon icon={faPhone} className="me-2 ms-2" />{t('NAVIGATION.CALL_CENTER')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/dashboard/plans">
                <FontAwesomeIcon icon={faDollarSign} className="me-2 ms-2" />{t('NAVIGATION.PLANS')}
              </Nav.Link>
            </Nav.Item>
            <div className='settings-sidebar'>
              <Nav.Item>
                <Nav.Link>
                  <Dropdown className="language-selector me-3">
                    <Dropdown.Toggle variant="light" id="language-dropdown">
                      <FontAwesomeIcon icon={faGlobe} className="language-icon" />
                      <span className="language-selector-text">{languageLabels[language]}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="language-dropdown-menu">
                      {Object.keys(languageLabels).map(lang => (
                        <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                          {languageLabels[lang]}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <Dropdown className="me-3">
                    <Dropdown.Toggle variant="light" id="avatar-dropdown">
                      <Image src={getAvatarSrc()} roundedCircle width="30" height="30" />
                      <span className="profile-name">{user.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="profile-dropdown-menu">
                      <Dropdown.Item as={NavLink} to="/dashboard/profile">Profile</Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Link>
              </Nav.Item>
            </div>
          </Nav>
          <div className="contact-info mt-auto">
            <h6>{t('Contact Us')}</h6>
            <p className="mb-1 emailsupport"><FontAwesomeIcon icon={faEnvelope} className='me-1 ms-1'/>support@codpricing.com</p>
            <p className="mb-0 emailsupport"><FontAwesomeIcon icon={faPhone} className="me-2" />+213560345302</p>
          </div>
        </Col>
        <Col xs={12} lg={10} className="page-content">
          <Outlet />
        </Col>
      </Row>
      <footer className="footerSticky mt-auto">
        <div>
          <small className="text-muted">
            © {new Date().getFullYear()} Cod Pricing. All rights reserved. <NavLink to="/privacy-policy" className="text-muted">Privacy Policy</NavLink> | <NavLink to="/terms" className="text-muted">Terms of Service</NavLink>
          </small>
        </div>
      </footer>
      {isSidebarOpen && <div className="backdrop open" onClick={() => setIsSidebarOpen(false)} />}
    </Container>
  );
};

export default DashboardLayout;