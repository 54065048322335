import React, { useState, useEffect } from 'react';
import { Table, Button, Form, FormControl, Modal, InputGroup, Container, Col, Row, Spinner, Pagination, Alert } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import axios from '../../utils/axios';
import Avatar1 from '../../assets/avatar1.jpg';
import Avatar2 from '../../assets/avatar2.jpg';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [renewModalInfo, setRenewModalInfo] = useState({ show: false, userId: null, planType: null });
  const [newUser, setNewUser] = useState({ avatar: '', name: '', email: '', country: '', phoneNumber: '', password: '' });
  const [errors, setErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [exportData, setExportData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 20, 50, 100, 'All'];
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [page, searchQuery, itemsPerPage]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const limit = itemsPerPage === 'All' ? undefined : itemsPerPage;
      const response = await axios.get('/user/all-users', { params: { search: searchQuery, page, limit } });
      setUsers(response.data.users || []);
      setTotalPages(limit ? Math.ceil(response.data.count / itemsPerPage) : 1);
    } catch (error) {
      console.error("Error fetching users", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllUsersForExport = async () => {
    try {
      const response = await axios.get('/user/all-users-export');
      setExportData(response.data || []);
    } catch (error) {
      console.error("Error fetching all users for export", error);
    }
  };

  const fetchAllUsersWithPlansForExport = async () => {
    try {
      const response = await axios.get('/user/all-users-with-plans-export');
      console.log("Export Users with Plans Data:", response.data); // Debug line to check data
      setExportData(response.data || []);
    } catch (error) {
      console.error("Error fetching users with plans for export", error);
    }
  };

  useEffect(() => {
    fetchAllUsersForExport();
  }, []);

  const handleAvatarChange = (avatar) => {
    setNewUser({ ...newUser, avatar });
  };

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/user', newUser);
      setUsers([...users, { ...newUser, id: response.data.userId }]);
      setShowUserModal(false);
      setAlert({ show: true, message: 'User created successfully.', variant: 'success' });
    } catch (error) {
      console.error("Error adding user", error);
      setAlert({ show: true, message: 'Error adding user.', variant: 'danger' });
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    const selectedValue = e.target.value;
    setItemsPerPage(selectedValue === 'All' ? 'All' : parseInt(selectedValue));
    setPage(1);
  };

  const handleConnectAsUser = async (userId) => {
    try {
      const response = await axios.post('/admin/admin-login-as-user', { userId });
      const { user } = response.data.result;
      localStorage.setItem('avatar', user.avatar);
      localStorage.setItem('name', (user.name || '').split(' ')[0]);
      navigate('/dashboard/simulations');
    } catch (error) {
      console.error("Error connecting as user", error);
    }
  };

  const handleVerifyUser = async (userId) => {
    try {
      await axios.put(`/user/verify/${userId}`);
      fetchUsers(); 
      setAlert({ show: true, message: 'User verified successfully.', variant: 'success' });
    } catch (error) {
      console.error("Error verifying user", error);
      setAlert({ show: true, message: 'Error verifying user.', variant: 'danger' });
    }
  };

  const handleDisableUser = async (userId) => {
    try {
      await axios.put(`/user/disable/${userId}`);
      fetchUsers();
      setAlert({ show: true, message: 'User disabled successfully.', variant: 'success' });
    } catch (error) {
      console.error("Error disabling user", error);
      setAlert({ show: true, message: 'Error disabling user.', variant: 'danger' });
    }
  };

  const handleLogout = () => {
    axios.get('/auth/logout')
      .then(() => {
        localStorage.removeItem('avatar');
        localStorage.removeItem('name');
        navigate('/');
        setAlert({ show: true, message: 'Logged out successfully.', variant: 'success' });
      })
      .catch(err => console.error("Error logging out", err));
  };

  const handleRenewPlanConfirm = async () => {
    try {
      const { userId, planType } = renewModalInfo;
      await axios.post('/admin/renew-plan', { userId, planType });
      setRenewModalInfo({ show: false, userId: null, planType: null });
      fetchUsers();
      setAlert({ show: true, message: 'Plan renewed successfully.', variant: 'success' });
    } catch (error) {
      console.error("Error renewing plan", error);
      setAlert({ show: true, message: 'Error renewing plan.', variant: 'danger' });
    }
  };

  const handleShowRenewModal = (userId, planType) => {
    setRenewModalInfo({ show: true, userId, planType });
  };

  const renderPlanDescription = (user) => {
    return (
      <>
        <strong>Status:</strong> {user.planStatus || 'N/A'}<br />
        <strong>End Date:</strong> {user.planEndDate || 'N/A'}<br />
        <strong>Amount:</strong> {user.planAmount || 'N/A'}<br />
        <strong>Type:</strong> {user.planType || 'N/A'}
      </>
    );
  };

  const renderPagination = () => {
    const pages = [];
    const showPageCount = 5;  // Number of pages to show around the current page
    const startPage = Math.max(1, page - Math.floor(showPageCount / 2));
    const endPage = Math.min(totalPages, startPage + showPageCount - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item key={i} active={i === page} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          <Pagination className="mr-3">
            <Pagination.First disabled={page === 1} onClick={() => handlePageChange(1)} />
            <Pagination.Prev disabled={page === 1} onClick={() => handlePageChange(page - 1)} />
            {pages}
            <Pagination.Next disabled={page === totalPages} onClick={() => handlePageChange(page + 1)} />
            <Pagination.Last disabled={page === totalPages} onClick={() => handlePageChange(totalPages)} />
          </Pagination>
          <span className="ml-3">Records per page:</span>
          <Form.Control 
            as="select" 
            className="ml-2 w-auto" 
            value={itemsPerPage} 
            onChange={handleItemsPerPageChange}
          >
            {itemsPerPageOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid className="align-items-center">
      <Row className="mt-3">
        <Col>
          <h1>Users</h1>
          <h5>Here you can manage your users.</h5>
        </Col>
      </Row>
      {alert.show && (
        <Alert variant={alert.variant} onClose={() => setAlert({ show: false })} dismissible>
          {alert.message}
        </Alert>
      )}
      <Row className="mb-3">
        <Col md={6}>
          <Form className="d-inline">
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Search for a user"
                className="mr-sm-2"
                value={searchQuery}
                onChange={handleSearch}
              />
              <Button variant="secondary" onClick={fetchUsers}>Search</Button>
            </InputGroup>
          </Form>
        </Col>
        <Col md={6} className="text-right">
          <Button variant="primary" onClick={() => setShowUserModal(true)}>Add User</Button>
          <CSVLink data={exportData} filename="users.csv">
            <Button variant="info" className="ml-2">Export to CSV</Button>
          </CSVLink>
          <CSVLink data={exportData} filename="users_with_plans.csv" asyncOnClick={true}>
      <Button variant="success" className="ml-2" onClick={fetchAllUsersWithPlansForExport}>
        Export User CSV with Plans
      </Button>
    </CSVLink>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Form.Control as="select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
            {itemsPerPageOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Form.Control>
        </Col>
      </Row> */}

           {/* Render Pagination at the top */}
           <Row>
        <Col>{renderPagination()}</Col>
      </Row>

      <Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Email Verification</th>
              <th>Active</th>
              <th>Previous Plan Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <Spinner animation="border" />
                </td>
              </tr>
            ) : (
              users.length > 0 ? users.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.email_verified ? 'Verified' : 'Unverified'}</td>
                  <td>{user.active ? 'Active' : 'Inactive'}</td>
                  <td>{renderPlanDescription(user)}</td>
                  <td>
                    <Button variant="warning" onClick={() => handleDisableUser(user.id)}>Disable</Button>
                    <Button variant="success" onClick={() => handleVerifyUser(user.id)}>Verify</Button>
                    <Button variant="info" onClick={() => handleConnectAsUser(user.id)}>Connect as User</Button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="8" className="text-center">No users found</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        {/* <Pagination>
          {totalPages > 10 ? (
            <>
              <Pagination.First onClick={() => setPage(1)} />
              <Pagination.Prev disabled={page === 1} onClick={() => setPage(page - 1)} />
              {Array.from({ length: Math.min(totalPages, 10) }, (_, i) => i + Math.max(0, page - 5))
                .map(pageNumber => (
                  <Pagination.Item key={pageNumber + 1} active={pageNumber + 1 === page} onClick={() => handlePageChange(pageNumber + 1)}>
                    {pageNumber + 1}
                  </Pagination.Item>
                ))}
              <Pagination.Next disabled={page === totalPages} onClick={() => setPage(page + 1)} />
              <Pagination.Last onClick={() => setPage(totalPages)} />
            </>
          ) : (
            Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
              <Pagination.Item key={pageNumber} active={pageNumber === page} onClick={() => handlePageChange(pageNumber)}>
                {pageNumber}
              </Pagination.Item>
            ))
          )}
        </Pagination> */}
      </Row>
           {/* Render Pagination at the bottom */}
           <Row>
        <Col>{renderPagination()}</Col>
      </Row>


      <Modal show={renewModalInfo.show} onHide={() => setRenewModalInfo({ show: false, userId: null, planType: null })}>
        <Modal.Header closeButton>
          <Modal.Title>Renew Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to renew this user's plan as a "{renewModalInfo.planType}" plan?</p>
          <Button variant="secondary" onClick={() => setRenewModalInfo({ show: false, userId: null, planType: null })}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRenewPlanConfirm}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddUser}>
            <Form.Group className="m-3">
              <Form.Label>Choose Avatar</Form.Label>
              <div className="d-flex justify-content-around">
                <div className="avatar-container" onClick={() => handleAvatarChange('avatar1')}>
                  <img
                    src={Avatar1}
                    alt="Avatar 1"
                    className={`avatar-img ${newUser.avatar === 'avatar1' ? 'selected' : ''}`}
                  />
                </div>
                <div className="avatar-container" onClick={() => handleAvatarChange('avatar2')}>
                  <img
                    src={Avatar2}
                    alt="Avatar 2"
                    className={`avatar-img ${newUser.avatar === 'avatar2' ? 'selected' : ''}`}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="name"
                  value={newUser.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                  autoComplete="name"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  value={newUser.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                  autoComplete="email"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Country</Form.Label>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="country"
                  value={newUser.country}
                  onChange={handleChange}
                  isInvalid={!!errors.country}
                  className="custom-select"
                >
                  <option value="">Choose...</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Libya">Libya</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3">
              <Form.Label>Phone Number</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={newUser.phoneNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="m-3 password-input-group">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  name="password"
                  value={newUser.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                  className="password-input"
                  autoComplete="current-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" className="mt-4 w-100 mb-2 custom-button">
                Create User
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Users;