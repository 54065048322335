import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import '../ProtectedRoute.css';
import { useTranslation } from 'react-i18next';

const AdminRoute = () => {
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axios.get('/admin/check-role', { withCredentials: true });
        setIsAdmin(response.data.isAdmin);
      } catch {
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };
    checkAdmin();
  }, []);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Row>
          <Col className="text-center">
            <Spinner animation="border" className='loading-spinner' role="status">
              <span className="visually-hidden">{t('GENERAL.LOADING')}</span>
            </Spinner>
            <div>{t('GENERAL.LOADING')}</div>
          </Col>
        </Row>
      </Container>
    );
  }

  return isAdmin ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default AdminRoute;