import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Form, Button, Container, Row, Col, InputGroup, Alert, Dropdown } from 'react-bootstrap';
import { UserContext } from '../../contexts/UserContext';
import { FaEye, FaEyeSlash, FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axios from '../../utils/axios';

import './Login.css';

const languageLabels = {
  en: 'English',
  fr: 'Français',
  ar: 'العربية'
};

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { updateUserContext } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const whatsappLink = `https://wa.me/+213560345302?text=${encodeURIComponent('Login Issue')}`;


  const validateForm = () => {
    const newErrors = {};
    if (!form.email) newErrors.email = t('Email is required.');
    if (!form.password) newErrors.password = t('Password is required.');
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post('/auth', form, { withCredentials: true });
      const { user, messageKey } = response.data.result;

      // Clear any error messages
      setErrorMessage('');
      setSuccessMessage(t(messageKey));

      // Save user details in local storage
      localStorage.setItem('avatar', user.avatar);
      const [firstName] = (user.name || '').split(' ');
      localStorage.setItem('name', firstName || '');

      // Update user context
      updateUserContext({ avatar: user.avatar, name: firstName || '' });
      navigate('/dashboard/simulations');
    } catch (error) {
      const messageKey = error.response?.data?.messageKey || 'invalidUsernameOrPassword';
      setErrorMessage(
        <div>
          {t(messageKey)}
          {error.response?.data?.resendLink && (
            <div>
              <Button variant="link" id='ResendVerificationEmailLogin' onClick={resendVerificationEmail}>{t('Resend Verification Email')}</Button>
            </div>
          )}
        </div>
      );
      // Clear any success messages
      setSuccessMessage('');
    }
  };


  const resendVerificationEmail = async () => {
    try {
      await axios.post('/signup/resend-verification', { email: form.email });
      setSuccessMessage(t('Verification email resent. Please check your inbox.'));
      // Clear any error messages
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(t('Failed to resend verification email.'));
      // Clear any success messages
      setSuccessMessage('');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.lang = lng;
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  return (
    <>
    <div className="custom-container">
      <div className="custom-sidebar col-lg-6 col-md-5 mb-4">
        <div className="logo-container">
          <h1 className='logo-font'>Cod Pricing</h1>
          <h5>{t('Your Competitive Advantage in Pricing')}</h5>
        </div>
      </div>
      <Container className="d-flex col-lg-6 col-md-7 align-items-center justify-content-center p-0 m-0">
        <Row className="w-100">
          <Col md={10} lg={8}>
            <div className="custom-form-container">
              <h5 className="text-center mb-4">{t('Login to your account')}</h5>
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="m-3">
                  <Form.Label>{t('Email')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      autoComplete="email"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="m-3">
                  <Form.Label>{t('Password')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      className="password-input"
                      autoComplete="current-password"
                    />
                    <Button
                      variant="outline-secondary"
                      className="password-toggle"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mb-2 custom-button">
                  {t('Login')}
                </Button>
                <div className="d-flex justify-content-between">
                  <Button variant="link" className="p-0 auth-link" onClick={() => navigate('/forget-password')}>
                    {t('Forgot your Password?')}
                  </Button>
                  <Button variant="link" className="p-0 auth-link" onClick={() => navigate('/signup')}>
                    {t('Create an Account')}
                  </Button>
                </div>
              </Form>


                            {/* New Section for Additional Links */}
                            <div className="mt-4 text-center">
                <p className="mb-1">Can't login? <a href={whatsappLink} className='auth-link'  target="_blank" rel="noopener noreferrer"><FaWhatsapp /> </a></p>
    
                <p>Follow us on:
                  {' '}
                  <a className='auth-link' href="https://www.instagram.com/codpricing?igsh=ejl2ajkyc2Foa3Jo" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                  {' '}
                  <a className='auth-link' href="https://www.youtube.com/@CODPricing1" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                </p>

                <p>                              {/* Privacy and Terms of Use */}
                              <Button variant="link" className="p-0 m-0" style={{fontSize:"12px", color:"#4A00E0"}} onClick={() => window.open('/privacy-policy-and-terms-of-use', '_blank')}>{t('Privacy Policy and Terms of Use')}</Button> 
</p>

              </div>


              <div className="d-flex mt-4 justify-content-end">
                <Dropdown className="custom-dropdown">
                  <Dropdown.Toggle variant="light" id="language-dropdown" className="custom-dropdown-toggle">
                    <FaGlobe className="m-1" />
                    <span className="m-1">{languageLabels[i18n.language]}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(languageLabels).map((lang) => (
                      <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
                        {languageLabels[lang]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            
          </Col>
        </Row>

      </Container>


    </div>  
    <footer className="text-center">
                <p>&copy; {new Date().getFullYear()} Cod Pricing. All rights reserved.</p>
              </footer>             
              </>
  );
};

export default Login;