import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card, Badge, Table, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Binance from "../assets/Binance.png";
import Redotpay from "../assets/Redotpay.png";
import LogoBinance from "../assets/binancelogo.png";
import LogoRedotpay from "../assets/redotpaylogo.png";
import LogoBaridi from "../assets/baridilogo.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const PayManually = () => {
  const { t } = useTranslation();

  const binanceDetails = {
    name: 'Binance',
    address: 'TXsDvuHq5BwdfeN9srGeV6E7RJfraUK6iA',
  };
  const redotpayDetails = {
    name: 'Redotpay',
    id: '1416216286',
    address: 'TWZaTWzB9RHd694TisS7PrWyigt24NRDyn',
  };

  const baridiDetails = {
    name: 'Baridi',
    address: '00799999002427762281',
  };

  const initialMonthlyPlan = {
    name: t('PLANS.MONTHLY_PLAN'),
    price: 3.99,
    displayPrice: '€3.99/month',
    id: 'prod_QbHNj1v38VS4UQ',
    type: 'monthly'
  };

  const initialYearlyPlan = {
    name: t('PLANS.YEARLY_PLAN'),
    price: 45,
    displayPrice: '€45/year (Save 25%)',
    id: 'prod_QbHNE3IYXGMxyW',
    type: 'yearly'
  };

   // New 3-months and 6-months plans
   const initialThreeMonthsPlan = {
    name: t('PLANS.THREE_MONTHS_PLAN'),
    originalPrice: 11.97,
    price: 11.97,
    displayPrice: '€11.97/3 months',
    id: 'prod_3MonthsId',  // Replace with actual product ID
    type: '3_months'
  };

  const initialSixMonthsPlan = {
    name: t('PLANS.SIX_MONTHS_PLAN'),
    originalPrice: 19.99,
    price: 19.99,
    displayPrice: '19.99/6 months',
    id: 'prod_6MonthsId',  // Replace with actual product ID
    type: '6_months'
  };



  const [selectedMethod, setSelectedMethod] = useState('');
  const [monthlyPlan] = useState(initialMonthlyPlan);
  const [yearlyPlan] = useState(initialYearlyPlan);
  const [threeMonthsPlan, setThreeMonthsPlan] = useState(initialThreeMonthsPlan);
  const [sixMonthsPlan, setSixMonthsPlan] = useState(initialSixMonthsPlan);

  const handleMethodChange = (event) => {
    setSelectedMethod(event.currentTarget.value);
  };

  const createWhatsAppLink = (number, message) => {
    return `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
  };

  const phoneMessage = t('Payment COD Pricing');
  const whatsappNumber = '+213560345302';

  const notify = () => toast.success('Text copied!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const getPaymentDetails = (method) => {
    if (method === 'Binance') {
      return (
        <Row>
          <Col lg={12}>
            <Card className="shadow-sm">
              <Card.Body>
                <Row>
                  <Col sm={12} xs={12} >
                    <Table responsive bordered striped hover size="sm" className='mt-3'>
                      <tbody>
                        <tr>
                          <td>{t('NETWORK')}:</td>
                          <td style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                            <CopyToClipboard text={"TRC20"} className="m-1" onCopy={notify}>
                              <Button variant="secondary" size="sm" className="ml-2">
                                <FontAwesomeIcon icon={faCopy} />
                              </Button>
                            </CopyToClipboard>
                            <h6 className="mb-0">TRC20</h6>

                          </td>
                        </tr>
                        <tr>
                          <td>{t('ADDRESS')}</td>
                          <td style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                            <CopyToClipboard text={binanceDetails.address} className="m-1" onCopy={notify}>
                              <Button variant="secondary" size="sm" className="ml-2">
                                <FontAwesomeIcon icon={faCopy} />
                              </Button>
                            </CopyToClipboard>
                            <h6 className="mb-0">{binanceDetails.address}</h6>

                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <div className="mb-2">
                  {t('OR_USE_THIS_QR')}
                </div>
                <div>
                  <img src={Binance} alt="Binance QR Code" className="img-fluid" style={{ width: '150px' }} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      );
    } else if (method === 'Redotpay') {
      return (
        <Card className="mt-3 p-5 shadow-sm">
          <Card.Body>
            <Table responsive bordered striped hover size="sm" className='mt-3'>
              <tbody>
                <tr>
                  <td>{t('ID')}</td>
                  <td style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <CopyToClipboard text={redotpayDetails.id} className="m-1" onCopy={notify}>
                      <Button variant="secondary" size="sm" className="ml-2">
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </CopyToClipboard>
                    {redotpayDetails.id}

                  </td>
                </tr>
                <tr>
                  <td>{t('ADDRESS')}</td>
                  <td style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <CopyToClipboard text={redotpayDetails.address} className="m-1" onCopy={notify}>
                      <Button variant="secondary" size="sm" className="ml-2">
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </CopyToClipboard>
                    <h6 className="mb-0">{redotpayDetails.address}</h6>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="mb-2">
              {t('OR_USE_THIS_QR')}
            </div>
            <div>
              <img src={Redotpay} alt="Redotpay QR Code" className="img-fluid" style={{ width: '150px' }} />
            </div>
          </Card.Body>
        </Card>
      );
    } else if (method === 'Baridi') {
      return (
        <Card className="mt-3 p-5 shadow-sm">
          <Card.Body>
            <Table responsive bordered striped hover size="sm" className='mt-3'>
              <tbody>
                <tr>
                  <td>{t('RIP')}</td>
                  <td style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <CopyToClipboard text={baridiDetails.address} className="m-1" onCopy={notify}>
                      <Button variant="secondary" size="sm" className="ml-2">
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </CopyToClipboard>
                    <h6 className="mb-0">{baridiDetails.address}</h6>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      );
    }
  };

  return (
    <Container className="my-4">
      <h1 className="mb-4">{t('PAYMENT.TITLE')}</h1>
      <ToastContainer />
      <Row className="cards-container justify-content-center">
        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
        <Card className="plan-card w-100 plan-card-0">
        <Card.Body className="d-flex flex-column text-center">
              <Card.Title>                {t('PLANS.MONTHLY_PLAN')}
              </Card.Title>
              <Card.Text>
                <span className="plan-price">
                  ${monthlyPlan.price.toFixed(2)}/  {t('PLANS.MONTH')}
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
          <Card className="plan-card w-100 plan-card-2">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="text-center plan-title">
                {t('PLANS.THREE_MONTHS_PLAN')}
              </Card.Title>
              <Card.Text className="text-center">
              <span className="plan-price">
              ${threeMonthsPlan.originalPrice.toFixed(2)}/  {t('PLANS.THREE_MONTHS')}

                    </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
          <Card className="plan-card w-100 plan-card-3">
            <Card.Body className="d-flex flex-column">
              <Card.Title className="text-center plan-title">
                {t('PLANS.SIX_MONTHS_PLAN')}
              </Card.Title>
              <Card.Text className="text-center">
              
              <span className="plan-price">
              ${sixMonthsPlan.originalPrice.toFixed(2)}/  {t('PLANS.SIX_MONTHS')}
                    </span>
                    
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
        <Card className="plan-card w-100 plan-card-1">
        <Card.Body className="d-flex flex-column text-center">
              <Card.Title> {t('PLANS.YEARLY_PLAN')}</Card.Title>
              <Card.Text>
                <span className="discounted-price">
                  ${yearlyPlan.price.toFixed(2)}/ {t('PLANS.YEAR')}
                </span>
                <Badge pill className="plan-savings">
                  {t('PLANS.20_OFF')}
                </Badge>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className="p-3 shadow-sm">
      <Card.Body>
      <Row className="justify-content-left">
        <Col lg={6}>
         
              <h3>{t('STEP_01.TITLE')}</h3>
              <h6>{t('STEP_01.DESCRIPTION')}</h6>

              <div
                className={`payment-method d-flex flex-column p-3 mb-3 ${selectedMethod === 'Binance' ? 'selected' : ''}`}
                onClick={() => setSelectedMethod('Binance')}
                style={{ cursor: 'pointer', border: selectedMethod === 'Binance' ? '2px solid #007bff' : '1px solid #ced4da', borderRadius: '5px' }}
              >
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    name="paymentMethod"
                    value="Binance"
                    checked={selectedMethod === 'Binance'}
                    onChange={handleMethodChange}
                    className="mx-2"
                  />
                  <img src={LogoBinance} alt="Binance Logo" style={{ width: '100px' }} />
                </div>

                {selectedMethod === 'Binance' && getPaymentDetails('Binance')}
              </div>

              <div
                className={`payment-method d-flex flex-column p-3 mb-3 ${selectedMethod === 'Redotpay' ? 'selected' : ''}`}
                onClick={() => setSelectedMethod('Redotpay')}
                style={{ cursor: 'pointer', border: selectedMethod === 'Redotpay' ? '2px solid #007bff' : '1px solid #ced4da', borderRadius: '5px' }}
              >
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    name="paymentMethod"
                    value="Redotpay"
                    checked={selectedMethod === 'Redotpay'}
                    onChange={handleMethodChange}
                    className="mx-2"
                  />
                  <img src={LogoRedotpay} alt="Redotpay Logo" style={{ width: '100px' }} />
                </div>

                {selectedMethod === 'Redotpay' && getPaymentDetails('Redotpay')}
              </div>

              <div
                className={`payment-method d-flex flex-column p-3 mb-3 ${selectedMethod === 'Baridi' ? 'selected' : ''}`}
                onClick={() => setSelectedMethod('Baridi')}
                style={{ cursor: 'pointer', border: selectedMethod === 'Baridi' ? '2px solid #007bff' : '1px solid #ced4da', borderRadius: '5px' }}
              >
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    name="paymentMethod"
                    value="Baridi"
                    checked={selectedMethod === 'Baridi'}
                    onChange={handleMethodChange}
                    className="mx-2"
                  />
                  <img src={LogoBaridi} alt="Baridi Logo" style={{ width: '70px' }} />
                </div>

                {selectedMethod === 'Baridi' && getPaymentDetails('Baridi')}
              </div>

</Col>
<Col lg={4}>
              <h3 className="mt-4">{t('STEP_02.TITLE')}</h3>
              <h6>{t('STEP_02.DESCRIPTION')}</h6>
              <ol>
                <li>{t('STEP_02.INSTRUCTION_01')}</li>
                <li>{t('STEP_02.INSTRUCTION_02')}</li>
                <li>{t('STEP_02.INSTRUCTION_03')}</li>
              </ol>
              <Button
                className="mt-2"
                variant="success"
                href={createWhatsAppLink(whatsappNumber, phoneMessage)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('CONTACT_WHATSAPP')}
              </Button>
              <Alert variant="warning" className="mt-3" >
                {t('ALERT')}
              </Alert>
            
        </Col>
        </Row>

        </Card.Body>
          </Card>
    </Container>
  );
};

export default PayManually;