import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, InputGroup, FormControl, Dropdown, Container, Row, Col, Pagination } from 'react-bootstrap';
import axios from '../../utils/axios';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPayment, setNewPayment] = useState({ status: 'paid', period_end: '', amount: '', plan_type: '' });
  const [errors, setErrors] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10); // Items per page

  useEffect(() => {
    fetchPayments();
  }, [currentPage, searchQuery]);

  const fetchPayments = async () => {
    try {
      const response = await axios.get('/payments/all-payments', {
        params: { search: searchQuery, page: currentPage, limit: itemsPerPage }
      });
      setPayments(response.data.payments);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching payments", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleDisablePayment = async (paymentId) => {
    try {
      await axios.put(`/payments/cancel-payment/${paymentId}`);
      fetchPayments();  // Refresh the payments list
    } catch (error) {
      console.error("Error cancelling payment", error);
    }
  };

  const handleCreatePaymentModal = () => {
    setShowPaymentModal(true);
  };

  const handleSearchUser = async () => {
    try {
      const response = await axios.get(`/user/search`, { params: { search: searchQuery } });
      setUserSearchResults(response.data);
    } catch (error) {
      console.error("Error searching users", error);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setUserSearchResults([]);
    setSearchQuery(`${user.email}`); // Show selected user's email in the search box
  };

  const handleChange = (e) => {
    setNewPayment({ ...newPayment, [e.target.name]: e.target.value });
  };

  const handleAddPayment = async (e) => {
    e.preventDefault();
    setErrors(''); // Reset errors

    if (!selectedUser) {
      setErrors('Please select a user');
      return;
    }
    console.log(newPayment);

    if (!newPayment.status || !newPayment.period_end || !newPayment.amount || !newPayment.plan_type) {
      setErrors('Please fill in all the fields');
      return;
    }

    try {
      const response = await axios.post('/payments/add-payment', { ...newPayment, userId: selectedUser.id, userEmail: selectedUser.email });
      console.log(response.data);
      setPayments([...payments, { ...response.data, userEmail: selectedUser.email, userId: selectedUser.id }]);
      setShowPaymentModal(false);
      setNewPayment({ status: '', period_end: '', amount: '', plan_type: '' });
      setSelectedUser(null);
      setSearchQuery('');
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Error adding payment";
      console.error(errorMsg, error);
      setErrors(errorMsg);
    }
  };

  const getStatusClass = (period_end) => {
    const today = new Date();
    const endDate = new Date(period_end);
    return endDate > today ? 'Active' : 'Unactive';
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return <Pagination>{items}</Pagination>;
  };

  return (
    <Container fluid className="align-items-center">
      <Row>
              <h1>Payments</h1>
      <Row>
        <Col>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Search Payments"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Col>
        <Col>
          <Button variant="primary" onClick={handleCreatePaymentModal}>
            Add Payment
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID Payment</th>
            <th>Status</th>
            <th>Period End</th>
            <th>User ID</th>
            <th>User Email</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Plan Type</th>
            <th>Method</th>
            <th>CheckoutId</th>
            <th>Active Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.id}</td>
              <td>{payment.status}</td>
              <td>{payment.period_end}</td>
              <td>{payment.userId}</td>
              <td>{payment.userEmail}</td>
              <td>{payment.amount}</td>
              <td>{payment.currency}</td>
              <td>{payment.plan_type}</td>
              <td>{payment.method}</td>
              <td>{payment.checkoutId}</td>
              <td>{getStatusClass(payment.period_end)}</td>
              <td>
                <Button variant="warning" onClick={() => handleDisablePayment(payment.id)}>
                  Disable
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {renderPagination()}

      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddPayment}>
            <Form.Group className="mb-3">
              <Form.Label>Search User by Email</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Enter user email"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearchUser();
                  }}
                />
              </InputGroup>
              {userSearchResults.length > 0 && (
                <Dropdown.Menu show>
                  {userSearchResults.map((user) => (
                    <Dropdown.Item key={user.id} onClick={() => handleUserSelect(user)}>
                      {user.id} - {user.email}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
              {errors.user && <Form.Text className="text-danger">{errors.user}</Form.Text>}
            </Form.Group>
            {selectedUser && <div className="mb-3"><strong>Selected User:</strong> {selectedUser.id} - {selectedUser.email}</div>}
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={newPayment.status}
                onChange={handleChange}
                isInvalid={!!errors.status}
              >
                <option value="paid">Paid</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Period End</Form.Label>
              <Form.Control
                type="date"
                name="period_end"
                value={newPayment.period_end}
                onChange={handleChange}
                isInvalid={!!errors.period_end}
              />
              <Form.Control.Feedback type="invalid">
                {errors.period_end}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Method of payment</Form.Label>
              <Form.Control 
                type="text"
                name="method"
                value="manually"
                onChange={handleChange}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={newPayment.amount}
                onChange={handleChange}
                isInvalid={!!errors.amount}
              />
              <Form.Control.Feedback type="invalid">
                {errors.amount}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                as="select"
                name="currency"
                value={newPayment.currency}
                onChange={handleChange}
              >
                <option value="">Choose...</option>
                <option value="DZD">DZD</option>
                <option value="EUR">Euro</option>
                <option value="USD">USD</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Plan Type</Form.Label>
              <Form.Control
                as="select"
                name="plan_type"
                value={newPayment.plan_type}
                onChange={handleChange}
                isInvalid={!!errors.plan_type}
              >
                <option value="">Choose...</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="test">Test Period</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.plan_type}
              </Form.Control.Feedback>
            </Form.Group>
            {errors && <Form.Text className="text-danger">{errors}</Form.Text>}
            <Button variant="primary" type="submit">
              Add Payment
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      </Row>
    </Container>
  );
};

export default Payments;