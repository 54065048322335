import React, { useState } from 'react';
import { Container, Row, Col, Nav, Navbar, Button, Alert } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';

const EmailVerification = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Container fluid className="dashboard-layout bg-light min-vh-100">
      <Row>
        <Col xs={12} className="p-1 header">
          <Navbar expand="lg" className="shadow-sm d-flex justify-content-between">
          <Navbar.Brand as={NavLink} to="/dashboard/simulations" className='logo-font d-flex align-items-center '>
              <h5>Cod Pricing</h5>
            </Navbar.Brand>
            <div className="d-flex align-items-center">
            </div>
          </Navbar>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-center align-items-center vh-100">
        <Col md={6} lg={4} className="p-4">
          <div className="form-container text-center p-4 shadow bg-white rounded">
            <h3 className="mb-4">Verify Your Email</h3>
            <Alert variant='info' className="text-start">
              A verification link has been sent to your email. Please click the link to verify your account.
            </Alert>
            <Button variant="primary" size="lg" onClick={() => navigate('/')} className="w-100 mt-3">
              Back to Login
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailVerification;