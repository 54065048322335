import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  InputGroup,
  Col,
  Table,
  Form,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faTruck } from "@fortawesome/free-solid-svg-icons";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import axios from "../../utils/axios";
import "./CallCenter.css";
import { useTranslation } from 'react-i18next';

const CallCenter = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    agent: "",
    salaryHours: "",
    confirmation_percentage: "",
    delivery_percentage: "",

    minTimeForCallPerAgent: "",
    maxTimeForCallPerAgent: "",

    minOrdersPerHourPerAgent: "",
    maxOrdersPerHourPerAgent: "",


    minTimeForCall: "",
    maxTimeForCall: "",
    minOrdersPerHour: "",
    maxOrdersPerHour: "",
    minOrdersPerDay: "",
    maxOrdersPerDay: "",
    minOrdersPerMonth: "",
    maxOrdersPerMonth: "",

    minTimeConfirmedForCall: "",
    maxTimeConfirmedForCall: "",
    minOrdersConfirmedPerHour: "",
    maxOrdersConfirmedPerHour: "",
    minOrdersConfirmedPerDay: "",
    maxOrdersConfirmedPerDay: "",
    minOrdersConfirmedPerMonth: "",
    maxOrdersConfirmedPerMonth: "",

    minTimeDeliveredForCall: "",
    maxTimeDeliveredForCall: "",
    minOrdersDeliveredPerHour: "",
    maxOrdersDeliveredPerHour: "",
    minOrdersDeliveredPerDay: "",
    maxOrdersDeliveredPerDay: "",
    minOrdersDeliveredPerMonth: "",
    maxOrdersDeliveredPerMonth: "",
  });

  const [originalData, setOriginalData] = useState(data);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchCallCenter = async () => {
      try {
        const response = await axios.get("/call-center", { withCredentials: true });
        if (response.data && response.data.length > 0) {
          setData(response.data[0]);
          setOriginalData(response.data[0]);
        } else {
          const defaultValues = {
            agent: 2,
            salaryHours: 8,
            confirmation_percentage: 60,
            delivery_percentage: 60,
            minOrdersPerHourPerAgent: 10,
            maxOrdersPerHourPerAgent: 20,
          };
          setData(defaultValues);
          setOriginalData(defaultValues);
        }
      } catch (error) {
        console.error("Error fetching call center:", error);
      }
    };

    fetchCallCenter();
  }, []);

  const formatNumber = (number) => {
    return number.toFixed(2).replace(",", ".");
  };

  const calculateValues = (data) => {
    const agent = Number(data.agent);
    const salaryHours = Number(data.salaryHours);
    const confirmation_percentage = Number(data.confirmation_percentage);
    const delivery_percentage = Number(data.delivery_percentage);
    const minOrdersPerHourPerAgent = Number(data.minOrdersPerHourPerAgent);
    const maxOrdersPerHourPerAgent = Number(data.maxOrdersPerHourPerAgent);

    const minOrdersPerHour = minOrdersPerHourPerAgent * agent;
    const maxOrdersPerHour = maxOrdersPerHourPerAgent * agent;

    const minTimeForCall = 60 / minOrdersPerHour;
    const maxTimeForCall = 60 / maxOrdersPerHour;

    const minOrdersPerDay = minOrdersPerHourPerAgent * agent * salaryHours;
    const maxOrdersPerDay = maxOrdersPerHourPerAgent * agent * salaryHours;

    const minOrdersPerMonth = minOrdersPerDay * 26;
    const maxOrdersPerMonth = maxOrdersPerDay * 26;

    // CONFIRMED ORDERS TABLE
    const minOrdersConfirmedPerHour = (minOrdersPerHourPerAgent * agent * confirmation_percentage) / 100;
    const maxOrdersConfirmedPerHour = (maxOrdersPerHourPerAgent * agent * confirmation_percentage) / 100;

    const minTimeConfirmedForCall = 60 / minOrdersConfirmedPerHour;
    const maxTimeConfirmedForCall = 60 / maxOrdersConfirmedPerHour;

    const minOrdersConfirmedPerDay = minOrdersConfirmedPerHour * salaryHours;
    const maxOrdersConfirmedPerDay = maxOrdersConfirmedPerHour * salaryHours;

    const minOrdersConfirmedPerMonth = minOrdersConfirmedPerDay * 26;
    const maxOrdersConfirmedPerMonth = maxOrdersConfirmedPerDay * 26;

    // DELIVERED ORDERS TABLE
    const minOrdersDeliveredPerHour = (minOrdersConfirmedPerHour * delivery_percentage) / 100;
    const maxOrdersDeliveredPerHour = (maxOrdersConfirmedPerHour * delivery_percentage) / 100;

    const minTimeDeliveredForCall = 60 / minOrdersDeliveredPerHour;
    const maxTimeDeliveredForCall = 60 / maxOrdersDeliveredPerHour;

    const minOrdersDeliveredPerDay = minOrdersDeliveredPerHour * salaryHours;
    const maxOrdersDeliveredPerDay = maxOrdersDeliveredPerHour * salaryHours;

    const minOrdersDeliveredPerMonth = minOrdersDeliveredPerDay * 26;
    const maxOrdersDeliveredPerMonth = maxOrdersDeliveredPerDay * 26;

    return {
      minOrdersPerHour: formatNumber(minOrdersPerHour),
      maxOrdersPerHour: formatNumber(maxOrdersPerHour),
      minTimeForCall: formatNumber(minTimeForCall),
      maxTimeForCall: formatNumber(maxTimeForCall),
      minOrdersPerDay: formatNumber(minOrdersPerDay),
      maxOrdersPerDay: formatNumber(maxOrdersPerDay),
      minOrdersPerMonth: formatNumber(minOrdersPerMonth),
      maxOrdersPerMonth: formatNumber(maxOrdersPerMonth),
      minOrdersConfirmedPerHour: formatNumber(minOrdersConfirmedPerHour),
      maxOrdersConfirmedPerHour: formatNumber(maxOrdersConfirmedPerHour),
      minTimeConfirmedForCall: formatNumber(minTimeConfirmedForCall),
      maxTimeConfirmedForCall: formatNumber(maxTimeConfirmedForCall),
      minOrdersConfirmedPerDay: formatNumber(minOrdersConfirmedPerDay),
      maxOrdersConfirmedPerDay: formatNumber(maxOrdersConfirmedPerDay),
      minOrdersConfirmedPerMonth: formatNumber(minOrdersConfirmedPerMonth),
      maxOrdersConfirmedPerMonth: formatNumber(maxOrdersConfirmedPerMonth),
      minOrdersDeliveredPerHour: formatNumber(minOrdersDeliveredPerHour),
      maxOrdersDeliveredPerHour: formatNumber(maxOrdersDeliveredPerHour),
      minTimeDeliveredForCall: formatNumber(minTimeDeliveredForCall),
      maxTimeDeliveredForCall: formatNumber(maxTimeDeliveredForCall),
      minOrdersDeliveredPerDay: formatNumber(minOrdersDeliveredPerDay),
      maxOrdersDeliveredPerDay: formatNumber(maxOrdersDeliveredPerDay),
      minOrdersDeliveredPerMonth: formatNumber(minOrdersDeliveredPerMonth),
      maxOrdersDeliveredPerMonth: formatNumber(maxOrdersDeliveredPerMonth),
    };
  };
  useEffect(() => {
    const values = calculateValues(data);

    data.minTimeForCallPerAgent = formatNumber(60 / Number(data.minOrdersPerHourPerAgent));
    data.maxTimeForCallPerAgent = formatNumber(60 / Number(data.maxOrdersPerHourPerAgent));
    setData((prevData) => ({
      ...prevData,
      ...values,
    }));
  }, [data.minOrdersPerHourPerAgent, data.maxOrdersPerHourPerAgent, data.confirmation_percentage, data.delivery_percentage, data.agent, data.salaryHours]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setIsEditing(true);
  };

  const handleSliderChange = (value, name) => {
    setData({
      ...data,
      [name]: value,
    });
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      if (data.id) {
        await axios.put(`/call-center/edit/${data.id}`, data, { withCredentials: true });
      } else {
        const response = await axios.post("/call-center/add", data, { withCredentials: true });
        setData(response.data);
      }
      setOriginalData(data);
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving call center:", error);
    }
  };

  const handleCancel = () => {
    setData(originalData);
    setIsEditing(false);
  };

  return (
    <Container className="call-center-container">
      <Row className="section-header">
        <Col className="section-header-left">
          <div className="section-title"> {t(
            'CALL_CENTER.CALL_CENTER',
          )} </div>
          <div className="section-description">
            {t(
              'CALL_CENTER.CALL_CENTER_DESCRIPTION')}
          </div>
        </Col>
        <Col xs="auto">
          {isEditing && (
            <>
              <Button variant="secondary" onClick={handleCancel} className="mx-2">
                {t('CALL_CENTER.CANCEL')}
              </Button>
              <Button variant="primary" onClick={handleSave} className="mx-2">
                {t('CALL_CENTER.SAVE')}
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Row>
        <Card className="mb-3 custom-card">
          <Card.Body>
            <Row>
              <Col md={6}>
                <h5>{t('CALL_CENTER.CALL_CENTER_SETTINGS')}</h5>
                <Form.Group controlId="agent" className="form-group-custom mb-3">
                  <Form.Label> {t('CALL_CENTER.AGENTS')} </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="agent"
                      value={data.agent}
                      onChange={handleChange}
                      className="form-control-custom form-control-call-center"
                    />
                    <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.AGENTS')} </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="salaryHours" className="form-group- mb-3">
                  <Form.Label> {t('CALL_CENTER.SALARY_HOURS')} </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="salaryHours"
                      value={data.salaryHours}
                      onChange={handleChange}
                      className="form-control-custom form-control-call-center"
                    />
                    <InputGroup.Text className="input-group-text-call-center">{t('CALL_CENTER.HOURS')}</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="confirmation_percentage" className="form-group-custom">
                  <Form.Label>
                    <FontAwesomeIcon icon={faSquareCheck} /> {t('CALL_CENTER.CONFIRMATION_PERCENTAGE')}
                  </Form.Label>
                  <Slider
                    min={0}
                    max={100}
                    value={data.confirmation_percentage || 0}
                    onChange={(value) => handleSliderChange(value, "confirmation_percentage")}
                    className="slider-custom"
                  />
                  <Form.Text className="form-text-custom">{data.confirmation_percentage}%</Form.Text>
                </Form.Group>
                <Form.Group controlId="delivery_percentage" className="form-group-custom">
                  <Form.Label>
                    <FontAwesomeIcon icon={faTruck} /> {t('CALL_CENTER.DELIVERY_PERCENTAGE')}
                  </Form.Label>
                  <Slider
                    min={0}
                    max={100}
                    value={data.delivery_percentage || 0}
                    onChange={(value) => handleSliderChange(value, "delivery_percentage")}
                    className="slider-custom"
                  />
                  <Form.Text className="form-text-custom">{data.delivery_percentage}%</Form.Text>
                </Form.Group>
                <tr>
                  <th style={{ verticalAlign: 'bottom' }}> {t('CALL_CENTER.ORDERS_PER_HOUR')} </th>
                  <td>
                    <Form.Group controlId="minOrdersPerHourPerAgent" className="form-group-custom">
                      <Form.Label>{t('CALL_CENTER.MIN')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="minOrdersPerHourPerAgent"
                          value={data.minOrdersPerHourPerAgent}
                          onChange={handleChange}
                          className="form-control-custom form-call-center"
                        />
                        <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.ORDERS')} </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="maxOrdersPerHourPerAgent" className="form-group-custom">
                      <Form.Label>{t('CALL_CENTER.MAX')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="maxOrdersPerHourPerAgent"
                          value={data.maxOrdersPerHourPerAgent}
                          onChange={handleChange}
                          className="form-control-custom"
                        />
                        <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.ORDERS')} </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </td>
                </tr>

                <tr>
                  <th style={{ verticalAlign: 'bottom' }} > {t('CALL_CENTER.TIME_FOR_CALL_PER_AGENT')} </th>
                  <td>
                    <Form.Group controlId="minTimeForCallPerAgent" className="form-group-custom">
                      <Form.Label>{t('CALL_CENTER.MIN')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="minTimeForCallPerAgent"
                          value={data.minTimeForCallPerAgent}
                          disabled
                          className="form-control-custom form-call-center"
                        />
                        <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.MINUTES')} </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="maxTimeForCallPerAgent" className="form-group-custom">
                      <Form.Label>{t('CALL_CENTER.MAX')}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="maxTimeForCallPerAgent"
                          value={data.maxTimeForCallPerAgent}
                          disabled className="form-control-custom"
                        />
                        <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.MINUTES')} </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </td>
                </tr>
              </Col>

              <Col md={6} className="ml-4">
                <h5> {t('CALL_CENTER.ALL_ORDERS')} </h5>
                <Table bordered responsive striped className="table-custom-call-center">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t('CALL_CENTER.MIN')}</th>
                      <th>{t('CALL_CENTER.MAX')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th> {t('CALL_CENTER.Estimated time for one call')} </th>
                      <td>
                        <Form.Group controlId="minTimeForCall" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              step="0.01"
                              name="minTimeForCall"
                              value={data.minTimeForCall}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.MINUTES')} </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxTimeForCall" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              step="0.01"
                              name="maxTimeForCall"
                              value={data.maxTimeForCall}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.MINUTES')} </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <th> {t('CALL_CENTER.ORDERS_PER_HOUR')} </th>
                      <td>
                        <Form.Group controlId="minOrdersPerHour" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="minOrdersPerHour"
                              value={data.minOrdersPerHour}
                              disabled
                              className="form-control-custom form-call-center"
                            />
                            <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.ORDERS')} </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxOrdersPerHour" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="maxOrdersPerHour"
                              value={data.maxOrdersPerHour}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center"> {t('CALL_CENTER.ORDERS')} </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <th className="table-label-left"> {t('CALL_CENTER.ORDERS_PER_DAY')} </th>
                      <td>
                        <Form.Group controlId="minOrdersPerDay" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="minOrdersPerDay"
                              value={data.minOrdersPerDay}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled"> {t('CALL_CENTER.ORDERS')} </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxOrdersPerDay" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="maxOrdersPerDay"
                              value={data.maxOrdersPerDay}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <th> {t('CALL_CENTER.ORDERS_PER_MONTH')} </th>
                      <td>
                        <Form.Group controlId="minOrdersPerMonth" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="minOrdersPerMonth"
                              value={data.minOrdersPerMonth}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxOrdersPerMonth" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="maxOrdersPerMonth"
                              value={data.maxOrdersPerMonth}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card className="mb-3 custom-card">
          <Card.Body>
            <Row>
              <Col md={6}>
                <h5> {t('CALL_CENTER.CONFIRMED_ORDERS')} </h5>
                <Table bordered responsive striped className="table-custom-call-center">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t('CALL_CENTER.MIN')}</th>
                      <th>{t('CALL_CENTER.MAX')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th> {t('CALL_CENTER.Estimated time for one call')} </th>
                      <td>
                        <Form.Group controlId="minTimeConfirmedForCall" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              step="0.01"
                              name="minTimeConfirmedForCall"
                              value={data.minTimeConfirmedForCall}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled"> {t('CALL_CENTER.MINUTES')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxTimeConfirmedForCall" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              step="0.01"
                              name="maxTimeConfirmedForCall"
                              value={data.maxTimeConfirmedForCall}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled"> {t('CALL_CENTER.MINUTES')} </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('CALL_CENTER.ORDERS_PER_HOUR')}</th>

                      <td>     <Form.Group controlId="minOrdersConfirmedPerHour" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="minOrdersConfirmedPerHour"
                            value={data.minOrdersConfirmedPerHour}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>

                      <td>     <Form.Group controlId="maxOrdersConfirmedPerHour" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="maxOrdersConfirmedPerHour"
                            value={data.maxOrdersConfirmedPerHour}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                    </tr>
                    <tr>
                      <th>{t('CALL_CENTER.ORDERS_PER_DAY')}</th>
                      <td>     <Form.Group controlId="minOrdersConfirmedPerDay" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="minOrdersConfirmedPerDay"
                            value={data.minOrdersConfirmedPerDay}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                      <td>     <Form.Group controlId="maxOrdersConfirmedPerDay" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="maxOrdersConfirmedPerDay"
                            value={data.maxOrdersConfirmedPerDay}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                    </tr>
                    <tr>
                      <th>{t('CALL_CENTER.ORDERS_PER_MONTH')}</th>
                      <td>     <Form.Group controlId="minOrdersConfirmedPerMonth" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="minOrdersConfirmedPerMonth"
                            value={data.minOrdersConfirmedPerMonth}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                      <td>     <Form.Group controlId="maxOrdersConfirmedPerMonth" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="maxOrdersConfirmedPerMonth"
                            value={data.maxOrdersConfirmedPerMonth}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={6}>
                <h5> {t('CALL_CENTER.DELIVERED_ORDERS')} </h5>
                <Table bordered responsive striped className="table-custom-call-center">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t('CALL_CENTER.MIN')}</th>
                      <th>{t('CALL_CENTER.MAX')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th> {t('CALL_CENTER.Estimated time for one call')}</th>
                      <td>
                        <Form.Group controlId="minTimeDeliveredForCall" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              step="0.01"
                              name="minTimeDeliveredForCall"
                              value={data.minTimeDeliveredForCall}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.MINUTES')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxTimeDeliveredForCall" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              step="0.01"
                              name="maxTimeDeliveredForCall"
                              value={data.maxTimeDeliveredForCall}
                              disabled className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.MINUTES')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('CALL_CENTER.ORDERS_PER_HOUR')}</th>
                      <td>
                        <Form.Group controlId="minOrdersDeliveredPerHour" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="minOrdersDeliveredPerHour"
                              value={data.minOrdersDeliveredPerHour}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="maxOrdersDeliveredPerHour" className="form-group-custom">
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name="maxOrdersDeliveredPerHour"
                              value={data.maxOrdersDeliveredPerHour}
                              disabled
                              className="form-control-custom"
                            />
                            <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </td>               </tr>

                    <tr>
                      <th>{t('CALL_CENTER.ORDERS_PER_DAY')}</th>
                      <td>     <Form.Group controlId="minOrdersDeliveredPerDay" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="minOrdersDeliveredPerDay"
                            value={data.minOrdersDeliveredPerDay}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                      <td>     <Form.Group controlId="maxOrdersDeliveredPerDay" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="maxOrdersDeliveredPerDay"
                            value={data.maxOrdersDeliveredPerDay}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                    </tr>
                    <tr>
                      <th>{t('CALL_CENTER.ORDERS_PER_MONTH')}</th>
                      <td>     <Form.Group controlId="minOrdersDeliveredPerMonth" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="minOrdersDeliveredPerMonth"
                            value={data.minOrdersDeliveredPerMonth}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                      <td>     <Form.Group controlId="maxOrdersDeliveredPerMonth" className="form-group-custom">
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="maxOrdersDeliveredPerMonth"
                            value={data.maxOrdersDeliveredPerMonth}
                            disabled
                            className="form-control-custom"
                          />
                          <InputGroup.Text className="input-group-text-call-center-disabled">{t('CALL_CENTER.ORDERS')}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default CallCenter;